export const LOADING = 'LOADING';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const CONTACT_LOADING = 'CONTACT_LOADING';
export const SSO_LOADING = 'SSO_LOADING';
export const CHALLENGE_LOADING = 'CHALLENGE_LOADING';
export const EVENT_LOADING = 'EVENT_LOADING';
export const SOCIAL_LOADING = 'SOCIAL_LOADING';
export const USER_PROFILE_LOADING = 'USER_PROFILE_LOADING';
export const WELLNESS_DASHBOARD_LOADING = 'WELLNESS_DASHBOARD_LOADING';
export const AUTH_LOADING = 'AUTH_LOADING';
export const EDUCATION_LOADING = 'EDUCATION_LOADING';
export const SET_DEVICE_DISCONNECTION = 'SET_DEVICE_DISCONNECTION';

/* admin user actionTypes*/
//admin user actionTypes
export const SET_ADMIN_USER = 'SET_ADMIN_USER';
export const GET_ADMIN_USER = 'GET_ADMIN_USER';

export const GET_USER_ROLE = 'GET_USER_ROLE';
export const SET_USER_ROLE = 'SET_USER_ROLE';

//Authentication actionTypes
export const AUTHENTICATED_USER = 'AUTHENTICATED_USER';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESSFULLY = 'LOGIN_SUCCESSFULLY';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const SET_COMPANY_LOCATION = 'SET_COMPANY_LOCATION';
export const SET_COMPANY_DEPARTMENT = 'SET_COMPANY_DEPARTMENT';
export const SET_PEOPLE_COMPANY_LOCATION = 'SET_PEOPLE_COMPANY_LOCATION';
export const SET_PEOPLE_COMPANY_DEPARTMENT = 'SET_PEOPLE_COMPANY_DEPARTMENT';
export const SET_COMPANY_LOCATION_NOT_NULL = 'SET_COMPANY_LOCATION_NOT_NULL';
export const SET_COMPANY_DEPARTMENT_NOT_NULL = 'SET_COMPANY_DEPARTMENT_NOT_NULL';
export const SET_COMPANY_LOCATION_NULL = 'SET_COMPANY_LOCATION_NULL';
export const SET_COMPANY_DEPARTMENT_NULL = 'SET_COMPANY_DEPARTMENT_NULL';
export const SHOW_DAILY_TIP_POPUP = 'SHOW_DAILY_TIP_POPUP';
export const AUTHENTICATED_USER_EMAIL_TOKEN = 'AUTHENTICATED_USER_EMAIL_TOKEN';

/* user profile actionTypes*/
//user profile actionTypes
export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const FETCH_PROFILE_DATA_FAILED = 'FETCH_PROFILE_DATA_FAILED';

export const GET_USER_NAME = 'GET_USER_NAME';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_BIOMETRIC_FORM_DATA = 'SET_BIOMETRIC_FORM_DATA';

export const GET_USER_PROFILE_IMAGE = 'GET_USER_PROFILE_IMAGE';
export const SET_USER_PROFILE_IMAGE = 'SET_USER_PROFILE_IMAGE';

export const GET_USER_POSTS = 'GET_USER_POSTS';
export const FAILED_GET_USER_POSTS = 'FAILED_GET_USER_POSTS';
export const GET_USER_POST_IMAGES = 'GET_USER_POST_IMAGES';
export const FAILED_GET_USER_POST_IMAGES = 'FAILED_GET_USER_POST_IMAGES ';
export const ADD_AND_UPDATE_LOCATION = 'ADD_AND_UPDATE_LOCATION';
export const ADD_AND_UPDATE_DEPARTMENTS = 'ADD_AND_UPDATE_DEPARTMENTS';
export const ADD_BULK_USER_FOR_LOCATION = 'ADD_BULK_USER_FOR_LOCATION';
export const ADD_BULK_USER_FOR_DEPARTMENT = 'ADD_BULK_USER_FOR_DEPARTMENT';


export const GET_USER_FRIENDS_LIST = 'GET_USER_FRIENDS_LIST';
export const FAILED_GET_USER_FRIENDS_LIST = 'FAILED_GET_USER_FRIENDS_LIST';

export const GET_USER_ID = 'GET_USER_ID';
export const SET_USER_ID = 'SET_USER_ID';

export const SAVE_SEARCH_AMIGO_INFO = 'SAVE_SEARCH_AMIGO_INFO';
export const CLEAR_SEARCH_AMIGO_INFO = 'CLEAR_SEARCH_AMIGO_INFO';
export const CLEAR_SEARCH_AMIGO_LIST = 'CLEAR_SEARCH_AMIGO_LIST';

export const SEARCH_AMIGO = 'SEARCH_AMIGO';
export const FAILED_SEARCH_AMIGO = 'FAILED_SEARCH_AMIGO';
export const GET_PAGE_NUMBER = 'GET_PAGE_NUMBER';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';

export const SET_USER_DATA = 'SET_USER_DATA';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_CONNECTED_DEVICES = 'GET_CONNECTED_DEVICES';
export const SET_CONNECTED_DEVICES = 'SET_CONNECTED_DEVICES';


export const SET_USER_HEALTH_DATA = 'SET_USER_HEALTH_DATA';
export const GET_USER_HEALTH_DATA = 'GET_USER_HEALTH_DATA';

export const SET_INTEREST_DATA = 'SET_INTEREST_DATA';
export const UPDATE_WELLNESS_LOCK_STATUS = 'UPDATE_WELLNESS_LOCK_STATUS';

export const OTHER_USER_DETAIL = 'OTHER_USER_DETAIL';
export const GET_USER_WELLNESS_INITIATIVE = 'GET_USER_WELLNESS_INITIATIVE';
export const GET_SHOUTOUT_COUNT = 'GET_SHOUTOUT_COUNT';

export const SET_USER_MONTHLY_DETAILS = 'SET_USER_MONTHLY_DETAILS';
export const SET_MONTHLY_GOAL = 'SET_MONTHLY_GOAL';
export const SET_LIST_OF_THINGS_TO_COMPLETE = 'SET_LIST_OF_THINGS_TO_COMPLETE';
export const SET_THEMES_HEADER = 'SET_THEMES_HEADER';
export const GET_UPCOMING_THEMES = 'GET_UPCOMING_THEMES';
export const SET_CHALLENGE_SURVEYS = 'SET_CHALLENGE_SURVEYS';
export const GET_USER_INITIATIVES = 'GET_USER_INITIATIVES';
export const FAILED_USER_INITIATIVES = 'FAILED_USER_INITIATIVES';
export const SUCCESSFULLY_USER_INITIATIVES = 'SUCCESSFULLY_USER_INITIATIVES';
export const GET_BIOMETRIC_DATA = 'GET_BIOMETRIC_DATA';
export const GETUSER_WELLBEING_ASSESSMENT_DETAILS = 'GETUSER_WELLBEING_ASSESSMENT_DETAILS';
export const GETUSER_WELLBEING_ASSESSMENT_DETAILSV2 = 'GETUSER_WELLBEING_ASSESSMENT_DETAILSV2';
export const SET_ALL_THEMES_HEADER = 'SET_ALL_THEMES_HEADER';


//user logout actionTypes
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

//company login actionTypes
export const COMPANY_LOGIN_SUCCESSFUL = 'COMPANY_LOGIN_SUCCESSFUL';
export const COMPANY_LOGIN_FAILED = 'COMPANY_LOGIN_FAILED';
export const COMPANY_LOGIN_ATTEMPT = 'COMPANY_LOGIN_ATTEMPT';
export const SAVE_COMPANY_ID = 'SAVE_COMPANY_ID';
export const GET_COMPANY_ID = 'GET_COMPANY_ID';
export const SET_REPORT_TAB = 'SET_REPORT_TAB';
// export const SET_DEFAULT_REPORT_TAB = 'SET_DEFAULT_REPORT_TAB';
export const GET_SPOUSE_DEPENDENT_INFO = 'GET_SPOUSE_DEPENDENT';
export const SET_SPOUSE_DEPENDENT_INFO = 'SET_SPOUSE_DEPENDENT_INFO';
export const GET_SPOUSE_DEPENDENT_AT_COMPANY_LOGIN = 'GET_SPOUSE_DEPENDENT_AT_COMPANY_LOGIN';

//user register actionTypes
export const SAVE_USER_INFORMATION = 'SAVE_USER_INFORMATION';
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const SHOW_CLOSE_WELCOME_BOX = "SHOW_CLOSE_WELCOME_BOX";
export const FETCH_SPOUSE_DEPENDENT = 'FETCH_SPOUSE_DEPENDENT';
export const FETCH_SPOUSE = 'FETCH_SPOUSE';
export const FETCH_DEPENDENT = 'FETCH_DEPENDENT';
export const CLEAR_USER_INFORMATION = 'CLEAR_USER_INFORMATION';
export const SIGNUP_BUTTON_STATUS = 'SIGNUP_BUTTON_STATUS';

//wellness interests actionTypes
export const GET_WELLNESS_INTERESTS = 'GET_WELLNESS_INTERESTS';
export const FAILED_GET_WELLNESS_INTERESTS = 'FAILED_GET_WELLNESS_INTERESTS';

//area of interests actionTypes
export const GET_AREA_OF_INTERESTS = 'GET_AREA_OF_INTERESTS';
export const ADD_AREA_OF_INTERESTS = 'ADD_AREA_OF_INTERESTS';

export const GET_FITNESS_GOALS = 'GET_FITNESS_GOALS';
export const FAILED_GET_FITNESS_GOALS = 'FAILED_GET_FITNESS_GOALS';

//long term goals actionTypes
export const GET_LONG_TERM_GOALS = 'GET_LONG_TERM_GOALS';
export const ADD_LONG_TERM_GOALS = 'ADD_LONG_TERM_GOALS';

//general health data actionTypes
export const GET_GENERAL_HEALTH_DATA = 'GET_GENERAL_HEALTH_DATA';
export const ADD_GENERAL_HEALTH_DATA = 'ADD_GENERAL_HEALTH_DATA';
export const CLEAR_GENERAL_HEALTH_DATA = 'CLEAR_GENERAL_HEALTH_DATA';
export const RESET_LOCATION_DEPARTMENT = 'RESET_LOCATION_DEPARTMENT';

//company lovation actionTypes
export const GET_COMPANY_LOCATION = 'GET_COMPANY_LOCATION';
export const SAVE_COMPANY_LOCATION = 'SAVE_COMPANY_LOCATION';

//sign up actionTypes
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_SUCCESSFULLY = 'SIGNUP_SUCCESSFULLY';
export const SIGNUP_ATTEMPT = 'SIGNUP_ATTEMPT';


/* challenges actionTypes */
//challenges data actionTypes
export const GET_CHALLENGES_DATA = 'GET_CHALLENGES_DATA';
export const FAILED_GET_CHALLENGES_DATA = 'FAILED_GET_CHALLENGES_DATA';

export const SUCCESSFULLY_CREATED_CHALLENGE = 'SUCCESSFULLY_CREATED_CHALLENGE';
export const FAILED_CREATE_CHALLENGE = 'FAILED_CREATE_CHALLENGE';

export const GET_CHALLENGE_DETAILS = 'GET_CHALLENGE_DETAILS';
export const FAILED_GET_CHALLENGE_DETAILS = 'FAILED_GET_CHALLENGE_DETAILS';
export const EMPTY_CHALLENGE_DETAILS = 'EMPTY_CHALLENGE_DETAILS';

export const GET_TEAM_CHALLENGE_DETAILS = 'GET_TEAM_CHALLENGE_DETAILS';
export const FAILED_GET_TEAM_CHALLENGE_DETAILS = 'FAILED_GET_TEAM_CHALLENGE_DETAILS';
export const EMPTY_TEAM_CHALLENGE_DETAILS = 'EMPTY_TEAM_CHALLENGE_DETAILS';

export const FETCH_BEHAVIOR_CHALLENGE_BY_ID = 'FETCH_BEHAVIOR_CHALLENGE_BY_ID';
export const FETCH_BEHAVIOR_CHALENGE_DATA_BY_CATEGORY = 'FETCH_BEHAVIOR_CHALENGE_DATA_BY_CATEGORY';
export const FETCH_BEHAVIOR_CHALLENGE_DETAILS = 'FETCH_BEHAVIOR_CHALLENGE_DETAILS';
export const GET_USER_BEHAVIOR_CHALLENGES = 'GET_USER_BEHAVIOR_CHALLENGES';
export const UPDATE_LIVE_CHALLENGES_STATUS = 'UPDATE_LIVE_CHALLENGES_STATUS';
export const FETCH_ACTIVITY_CHALLENGES_LIBRARY = 'FETCH_ACTIVITY_CHALLENGES_LIBRARY';
export const FETCH_ACTIVITY_CHALLENGE_BY_ID = 'FETCH_ACTIVITY_CHALLENGE_BY_ID';
export const FETCH_CHALLENGE_SUBCATEGORY = 'FETCH_CHALLENGE_SUBCATEGORY';
export const FETCH_CHALLENGE_DETAILS_BY_SUBCATEGORY = 'FETCH_CHALLENGE_DETAILS_BY_SUBCATEGORY';
export const GET_USER_ACTIVITY_CHALLENGES = 'GET_USER_ACTIVITY_CHALLENGES';
export const SET_USER_LIVE_CHALLENGES_STATUS = 'SET_USER_LIVE_CHALLENGES_STATUS';
export const TOGGLE_DUMMY_CHALLENGES = 'TOGGLE_DUMMY_CHALLENGES';
export const SET_CHALLENGE_INVITE_STATUS = 'SET_CHALLENGE_INVITE_STATUS';
export const GET_INVITE_AMIGO_LIST = 'GET_INVITE_AMIGO_LIST';
export const INVITE_LOADING = 'INVITE_LOADING';
export const USER_CHALLENGE_COMPLETED_POPUP = 'USER_CHALLENGE_COMPLETED_POPUP';
export const USER_RECOMMENDED_CHALLENGE = 'USER_RECOMMENDED_CHALLENGE';
export const USER_RECOMMENDED_WELLNESS_CHALLANGE = 'USER_RECOMMENDED_WELLNESS_CHALLANGE'
export const USER_CHALLENGE_INVITATION = 'USER_CHALLENGE_INVITATION';
export const REMOVE_USER_CHALLENGE_INVITATION = 'REMOVE_USER_CHALLENGE_INVITATION';
export const ACTIVITY_CHALLENGE_BY_ID = 'ACTIVITY_CHALLENGE_BY_ID';
export const GET_CHALLENGE_PARTICIPANTS_COUNT = 'GET_CHALLENGE_PARTICIPANTS_COUNT';
export const ACTIVITY_CHALLENGE_TYPE = 'ACTIVITY_CHALLENGE_TYPE';
export const GET_CHALLENGE_TYPE_BY_CHALLENGE = 'GET_CHALLENGE_TYPE_BY_CHALLENGE';
export const BEHAVIOR_CHALLENGE_TYPE = 'BEHAVIOR_CHALLENGE_TYPE';

//Join/leave challenge
export const JOIN_LEAVE_CHALLENGE_SUCCESS = 'JOIN_LEAVE_CHALLENGE_SUCCESS';
export const JOIN_LEAVE_CHALLENGE_FAILED = 'JOIN_LEAVE_CHALLENGE_FAILED';

//Delete challenge
export const DELETE_CHALLENGE = 'DELETE_CHALLENGE';

//Delete Challenge Library
export const DELETE_CHALLENGE_LIBRARY = 'DELETE_CHALLENGE_LIBRARY';

//get comments on challenge actionTypes
export const GET_COMMENTS_ON_CHALLENGE = 'GET_COMMENTS_ON_CHALLENGE';
export const FAILED_GET_COMMENTS_ON_CHALLENGE = 'FAILED_GET_COMMENTS_ON_CHALLENGE';

//add comment on challenge actionTypes
export const ADD_COMMENT_ON_CHALLENGE = 'ADD_COMMENT_ON_CHALLENGE';
export const FAILED_ADD_COMMENT_ON_CHALLENGE = 'FAILED_ADD_COMMENT_ON_CHALLENGE';

//active tab actionType
export const GET_ACTIVE_TAB = 'GET_ACTIVE_TAB';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const SAVE_CHALLENGE_DATA = 'SAVE_CHALLENGE_DATA';
export const GET_CHALLENGE_DATA = 'GET_CHALLENGE_DATA';

export const GET_CHALLENGE_COMPETITORS = 'GET_CHALLENGE_COMPETITORS';
export const FAILED_GET_CHALLENGE_COMPETITORS = 'FAILED_GET_CHALLENGE_COMPETITORS';

export const ADD_COMMENT_REPLY_ON_CHALLENGE = 'ADD_COMMENT_REPLY_ON_CHALLENGE';
export const FAILED_ADD_COMMENT_REPLY_ON_CHALLENGE = 'FAILED_ADD_COMMENT_REPLY_ON_CHALLENGE';

export const GET_TEAM_CHALLENGE = 'GET_TEAM_CHALLENGE';
export const FAILED_GET_TEAM_CHALLENGE = 'FAILED_GET_TEAM_CHALLENGE';


/* social feeds actionTypes*/

export const GET_BIRTHDAY_ANNIVERSARY_WISH_STATUS = 'GET_BIRTHDAY_ANNIVERSARY_WISH_STATUS';
export const GET_BIRTHDAY_LIST = 'GET_BIRTHDAY_LIST';
export const SET_USER_FIRST_NAME = 'SET_USER_FIRST_NAME';
export const SET_FEEDS_DATA_GOBALLY = 'SET_FEEDS_DATA_GOBALLY';
export const GET_ANNIVERSARY_BIRTHDAY = 'GET_ANNIVERSARY_BIRTHDAY';
//PostSection
export const GET_LIKE_DETAILS = 'GET_LIKE_DETAILS';
export const GET_UNFRIEND_USER = 'GET_UNFRIEND_USER';

//get social posts actionTypes
export const GET_SOCIAL_POSTS = 'GET_SOCIAL_POSTS';
export const FAILED_GET_SOCIAL_POSTS = 'FAILED_GET_SOCIAL_POSTS';
export const GET_RECOMMENDED_LIST_STATUS = 'GET_RECOMMENDED_LIST_STATUS';
export const GET_MY_AMIGO_SOCIAL_POSTS = 'GET_MY_AMIGO_SOCIAL_POSTS';

//get comments on post by id actionTypes
export const GET_COMMENTS_ON_POST = 'GET_COMMENTS_ON_POST';
export const FAILED_GET_COMMENTS_ON_POST = 'FAILED_GET_COMMENTS_ON_POST';

//add comment on post actiontypes
export const ADD_COMMENT_ON_POST = 'ADD_COMMENT_ON_POST';
export const FAILED_ADD_COMMENT_ON_POST = 'FAILED_ADD_COMMENT_ON_POST';

//get friend requests actionTypes
export const GET_FRIEND_REQUESTS = 'GET_FRIEND_REQUESTS';
export const FAILED_GET_FRIEND_REQUESTS = 'FAILED_GET_FRIEND_REQUESTS';

//accept friend request actionTypes
export const ACCEPT_REJECT_FRIEND_REQUEST = 'ACCEPT_REJECT_FRIEND_REQUEST';
export const FAILED_ACCEPT_REJECT_FRIEND_REQUEST= 'FAILED_ACCEPT_REJECT_FRIEND_REQUEST';

//add post actionTypes
export const ADD_SOCIAL_INSPIRATION = 'ADD_SOCIAL_INSPIRATION';
export const FAILED_ADD_SOCIAL_INSPIRATION = 'FAILED_ADD_SOCIAL_INSPIRATION';
export const SOCIAL_POST_DELETE = 'SOCIAL_POST_DELETE';

// get exemplifiesvalues
export const GET_COMPANY_EXEMPLIFIES_VALUES = 'GET_COMPANY_EXEMPLIFIES_VALUES';

//line/unlike post actionTypes
export const LIKE_UNLIKE_POST = 'LIKE_UNLIKE_POST';
export const FAILED_LIKE_UNLIKE_POST = 'FAILED_LIKE_UNLIKE_POST';

export const GET_ACTIVITY_FEEDS = 'GET_ACTIVITY_FEEDS';
export const FAILED_GET_ACTIVITY_FEEDS = 'FAILED_GET_ACTIVITY_FEEDS';

export const DISABLE_BUTTON = 'DISABLE_BUTTON';
export const ENABLE_BUTTON = 'ENABLE_BUTTON';

export const ADD_COMMENT_REPLY_ON_POST = 'ADD_COMMENT_REPLY_ON_POST';
export const FAILED_ADD_COMMENT_REPLY_ON_POST = 'FAILED_ADD_COMMENT_REPLY_ON_POST';

export const GET_ALL_MY_AMIGO_INSPIRATION_POST = 'GET_ALL_MY_AMIGO_INSPIRATION_POST';
export const FAILED_GET_ALL_MY_AMIGO_INSPIRATION_POST = 'FAILED_GET_ALL_MY_AMIGO_INSPIRATION_POST'

export const UPDATE_POST = 'UPDATE_POST';

export const UPDATE_EDIT_POSTS = 'UPDATE_EDIT_POSTS';
export const UPDATE_LIST = 'UPDATE_LIST';

export const GET_WELLNESS_EXPERT = 'GET_WELLNESS_EXPERT';
export const FAILED_GET_WELLNESS_EXPERT = 'FAILED_GET_WELLNESS_EXPERT';

export const SEND_FRIEND_REQUEST = 'SEND_FRIEND_REQUEST';
export const FAILED_SEND_FRIEND_REQUEST = 'FAILED_SEND_FRIEND_REQUEST';
export const UPDATE_AMIGO = 'UPDATE_AMIGO';

export const REMOVE_FRIEND = 'REMOVE_FRIEND';
export const FAILED_REMOVE_FRIEND = 'FAILED_REMOVE_FRIEND';

export const GET_INSPIRATION_POST_BY_ID = 'GET_INSPIRATION_POST_BY_ID';
export const GET_FEED_HIGHLIGHTS = 'GET_FEED_HIGHLIGHTS';

/* wellness dashboard actionTypes*/
export const UPDATE_PRIVACY_POLICY = 'UPDATE_PRIVACY_POLICY';
export const GET_COMPANY_BRANDING_DETAILS = 'GET_COMPANY_BRANDING_DETAILS';
export const GET_ACTIVE_CHALLENGE = 'GET_ACTIVE_CHALLENGE';
export const SHOW_BIOMETRIC_STATUS = 'SHOW_BIOMETRIC_STATUS'
export const GET_UPCOMING_CHALLENGE = 'GET_UPCOMING_CHALLENGE';

//daily tip actionTypes
export const SET_DAILY_TIP = 'SET_DAILY_TIP';
export const GET_DAILY_TIP = 'GET_DAILY_TIP';
export const UPDATE_DAILY_TIP = 'UPDATE_DAILY_TIP';

//motivational quote actionTypes
export const SET_MOTIVATIONAL_QUOTE = 'SET_MOTIVATIONAL_QUOTE';
export const GET_MOTIVATIONAL_QUOTE = 'GET_MOTIVATIONAL_QUOTE';

export const GET_ACTIVITY_OVERVIEW = 'GET_ACTIVITY_OVERVIEW';
export const FAILED_GET_ACTIVITY_OVERVIEW = 'FAILED_GET_ACTIVITY_OVERVIEW';
export const GET_DAILY_ACTIVITY_OVERVIEW = 'GET_DAILY_ACTIVITY_OVERVIEW';
export const DAILY_ACTIVITY_LOADING= 'DAILY_ACTIVITY_LOADING';
export const GET_MONTHLY_ACTIVITY_OVERVIEW = 'GET_MONTHLY_ACTIVITY_OVERVIEW';
export const GET_USER_REWARDS_DETAILS = 'GET_USER_REWARDS_DETAILS';
export const GET_DAILY_ACTIVITY_DATA = 'GET_DAILY_ACTIVITY_DATA';
export const SET_DAILY_ACTIVITY_DATA = 'SET_DAILY_ACTIVITY_DATA';
export const GET_USER_REWARD_HISTORY_DETAILS = 'GET_USER_REWARD_HISTORY_DETAILS';

export const SET_WELLNESS_GOALS = 'SET_WELLNESS_GOALS';
export const GET_WELLNESS_GOALS = 'GET_WELLNESS_GOALS';
export const UPDATE_WELLNESS_GOALS = 'UPDATE_WELLNESS_GOALS';

export const GET_ACHIEVED_GOALS = 'GET_ACHIEVED_GOALS';
export const SET_ACHIEVED_GOALS = 'SET_ACHIEVED_GOALS';

export const GET_LIST_OF_DEVICES = 'GET_LIST_OF_DEVICES';
export const FAILED_GET_LIST_OF_DEVICES = 'FAILED_GET_LIST_OF_DEVICES';

export const CONNECT_DEVICE = 'CONNECT_DEVICE';
export const REVOKE_DEVICE = 'REVOKE_DEVICE';
export const FAILED_CONNECT_OR_REVOKE_DEVICE = 'FAILED_CONNECT_OR_REVOKE_DEVICE';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';

export const SET_DAILY_GOAL = 'SET_DAILY_GOAL';

export const GET_LIST_OF_THINGS = 'GET_LIST_OF_THINGS';
export const FAILED_GET_LIST_OF_THINGS = 'FAILED_GET_LIST_OF_THINGS';

export const GET_REWARD_POINTS = 'GET_REWARD_POINTS';
export const FAILED_GET_REWARD_POINTS = 'FAILED_GET_REWARD_POINTS';
export const GET_ADMIN_MONTHLY_REWARD_POINTS = 'GET_ADMIN_MONTHLY_REWARD_POINTS';
export const FAILED_GET_ADMIN_REWARD_POINTS = 'FAILED_GET_ADMIN_REWARD_POINTS';
export const GET_REWARD_HISTORY_POINTS = 'GET_REWARD_HISTORY_POINTS';

/* Profile Picture */
export const UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE';

/* event actionTypes */
//create event actionTypes
export const SUCCESSFULLY_CREATED_EVENT = 'SUCCESSFULLY_CREATED_EVENT';
export const FAILED_CREATE_EVENT = 'FAILED_CREATE_EVENT';

export const GET_PLACES = 'GET_PLACES';
export const FAILED_GET_PLACES = 'FAILED_GET_PLACES';
export const GET_EVENTS = 'GET_EVENTS';
export const FAILED_GET_EVENTS = 'FAILED_GET_EVENTS';
export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const FAILED_GET_EVENT_DETAILS = 'FAILED_GET_EVENT_DETAILS';
export const GET_SEARCHED_ATTENDEES_LIST = 'GET_SEARCHED_ATTENDEES_LIST';
export const SET_FEATURED_EVENT = 'SET_FEATURED_EVENT';
export const GET_FEATURED_EVENT = 'GET_FEATURED_EVENT';
export const SET_FEATURED_CHALLENGE = 'SET_FEATURED_CHALLENGE';
export const SET_TEAM_CHALLENGE = 'SET_TEAM_CHALLENGE';
export const GET_FEATURED_CHALLENGE = 'GET_FEATURED_CHALLENGE';
export const FAILED_GET_ACTIVE_EVENTS = 'FAILED_GET_ACTIVE_EVENTS';
export const GET_ACTIVE_EVENTS = 'GET_ACTIVE_EVENTS';
export const FAILED_GET_PAST_EVENTS = 'FAILED_GET_PAST_EVENTS';
export const GET_PAST_EVENTS = 'GET_PAST_EVENTS';
export const FAILED_ONDEMAND_EVENT_DETAILS = 'FAILED_ONDEMAND_EVENT_DETAILS';
export const ONDEMAND_EVENT_DETAILS = 'ONDEMAND_EVENT_DETAILS';
export const FAILED_CREATE_ONDEMAND_EVENT = 'FAILED_CREATE_ONDEMAND_EVENT';
export const SUCCESSFULLY_CREATED_ONDEMAND_EVENT = 'SUCCESSFULLY_CREATE_ONDEMANDD_EVENT';
export const ONDEMAND_CATEGORY = 'ONDEMAND_CATEGORY';
export const FAILED_ONDEMAND_CATEGORY = 'FAILED_ONDEMAND_CATEGORY';
export const ONDEMAND_CATEGORY_BY_ID = 'ONDEMAND_CATEGORY_BY_ID';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_ONDEMAND = 'DELETE_ONDEMAND';
export const GET_COMPANY_EVENTS = 'GET_COMPANY_EVENTS';
export const GET_WOLIBA_EVENTS = 'GET_WOLIBA_EVENTS';
export const GET_USER_EVENT_INVITATION = 'GET_USER_EVENT_INVITATION';
export const GET_USER_LIST_EVENTS = 'GET_USER_LIST_EVENTS';

//get comments on challenge actionTypes
export const GET_COMMENTS_ON_EVENT = 'GET_COMMENTS_ON_EVENT';
export const FAILED_GET_COMMENTS_ON_EVENT = 'FAILED_GET_COMMENTS_ON_EVENT';

//add comment on challenge actionTypes
export const ADD_COMMENT_ON_EVENT = 'ADD_COMMENT_ON_EVENT';
export const FAILED_ADD_COMMENT_ON_EVENT = 'FAILED_ADD_COMMENT_ON_EVENT';

export const ADD_COMMENT_REPLY_ON_EVENT = 'ADD_COMMENT_REPLY_ON_EVENT';
export const FAILED_ADD_COMMENT_REPLY_ON_EVENT = 'FAILED_ADD_COMMENT_REPLY_ON_EVENT';

export const ATTEND_EVENT = 'ATTEND_EVENT';
export const FAILED_ATTEND_EVENT = 'FAILED_ATTEND_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';

export const GET_EVENT_ATTENDEES = 'GET_EVENT_ATTENDEES';
export const FAILED_GET_EVENT_ATTENDEES = 'FAILED_GET_EVENT_ATTENDEES';

export const SET_ATTENDING_STATUS = 'SET_ATTENDING_STATUS';
export const GET_ATTENDING_STATUS = 'GET_ATTENDING_STATUS';

export const CHANGE_WEEK_DASHBOARD = 'CHANGE_WEEK_DASHBOARD';

/* health risk assessment actionTypes */
export const SAVE_MEDICAL_DATA = 'SAVE_MEDICAL_DATA';
export const GET_MEDICAL_DATA = 'GET_MEDICAL_DATA';

export const SAVE_LIFESTYLE_DATA = 'SAVE_LIFESTYLE_DATA';
export const GET_LIFESTYLE_DATA = 'GET_LIFESTYLE_DATA';

export const SAVE_FITNESS_DATA = 'SAVE_FITNESS_DATA';
export const GET_FITNESS_DATA = 'GET_FITNESS_DATA';

export const SAVE_STRESS_DATA = 'SAVE_STRESS_DATA';
export const GET_STRESS_DATA = 'GET_STRESS_DATA';

export const SAVE_NUTRITION_DATA = 'SAVE_NUTRITION_DATA';
export const GET_NUTRITION_DATA = 'GET_NUTRITION_DATA';

export const SAVE_WORKPLACE_DATA = 'SAVE_WORKPLACE_DATA';
export const GET_WORKPLACE_DATA = 'GET_WORKPLACE_DATA';

export const SAVE_WELLBEING_ASSESSMENT_ERROR = 'SAVE_WELLBEING_ASSESSMENT_ERROR';
export const SAVE_WELLBEING_ASSESSMENT_SUCCESS = 'SAVE_WELLBEING_ASSESSMENT_SUCCESS';
export const CLEAR_WELLBEING_ASSESSMENT = 'CLEAR_WELLBEING_ASSESSMENT';
export const HRA_QUESTIONS_ANSWERS = 'HRA_QUESTIONS_ANSWERS';

/* SettingsPage ActionTypes */

export const UPDATE_USER_PROFILE = 'UPDATE_PROFILE';
export const SET_INPUT_ACTIVITY_GOALS = 'SET_INPUT_ACTIVITY_GOALS';
export const GET_INPUT_ACTIVITY_GOALS = 'GET_INPUT_ACTIVITY_GOALS';
export const SET_WELLNESS_JOURNEY = 'SET_WELLNESS_JOURNEY';
export const GET_WELLNESS_JOURNEY = 'GET_WELLNESS_JOURNEY';
export const SET_WELLNESS_VIDEO = 'SET_WELLNESS_VIDEO';
export const GET_WELLNESS_VIDEO = 'GET_WELLNESS_VIDEO';

/* PeopleHomePage actions*/
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const GET_SORTED_EMPLOYEES = 'GET_SORTED_EMPLOYEES';

/* educationPage ActionTypes */
export const GET_ADVENTURE_CATEGORIES = 'GET_ADVENTURE_CATEGORIES';
export const GET_THEMES = 'GET_THEMES';
export const GET_SELECTED_ADVENTURE_CATEGORIES = 'ALL_ADVENTURE_CATEGORIES';
export const FALSE_LOADING_CATEGORIES = 'FALSE_LOADING_CATEGORIES';
export const FAILED_GET_ADVENTURE_CATEGORIES = 'FAILED_GET_ADVENTURE_CATEGORIES';
export const SET_FEATURED_JOURNEY = 'SET_FEATURED_JOURNEY';
export const GET_CATEGORY_ADVENTURES = 'GET_CATEGORY_ADVENTURES';
export const FAILED_GET_CATEGORY_ADVENTURES = 'FAILED_GET_CATEGORY_ADVENTURES';
export const GET_ADVENTURE_ARTICLE = 'GET_ADVENTURE_ARTICLE';
export const FAILED_GET_ADVENTURE_ARTICLE = 'FAILED_GET_ADVENTURE_ARTICLE';
export const GET_VIDEOS_LIBRARY = 'GET_VIDEOS_LIBRARY';
export const GET_SELECTED_VIDEOS_LIBRARY = 'GET_SELECTED_VIDEOS_LIBRARY';
export const GET_VIDEOS = 'GET_VIDEOS';
export const GET_VIDEO_LIBRARY_ARTICLE = 'GET_VIDEO_LIBRARY_ARTICLE';
export const GET_RECOMMENDED_VIDEOS = 'GET_RECOMMENDED_VIDEOS';
export const FAILED_GET_RECOMMENDED_VIDEOS = 'FAILED_GET_RECOMMENDED_VIDEOS';
export const UPDATE_GLOBAL_POINTS = 'UPDATE_GLOBAL_POINTS';
export const FAILED_UPDATE_GLOBAL_POINTS = 'FAILED_UPDATE_GLOBAL_POINTS';
export const UPDATE_LOCK_STATUS = 'UPDATE_LOCK_STATUS';
export const FAILED_UPDATE_LOCK_STATUS = 'FAILED_UPDATE_LOCK_STATUS';
export const FETCH_QUIZZES = 'FETCH_QUIZZES';
export const FETCH_SCORE = 'FETCH_SCORE';
export const ADVENTURE_SCORE_BOARD = 'ADVENTURE_SCORE_BOARD';
export const CLEAR_THE_QUIZ = 'CLEAR_THE_QUIZ';
export const GET_WEBINAR_VIDEO_CATEGORY = 'GET_WEBINAR_VIDEO_CATEGORY';
export const GET_SELECTED_WEBINAR_VIDEO_CATEGORY = 'GET_SELECTED_WEBINAR_VIDEO_CATEGORY';
export const GET_WEBINAR_VIDEOS = 'GET_WEBINAR_VIDEO';
export const GET_WEBINAR_LIBRARY_ARTICLE = 'GET_WEBINAR_LIBRARY_ARTICLE';
export const QUIZ_SUBMIT_SUCCESS = 'QUIZ_SUBMIT_SUCCESS';
export const QUIZ_SUBMIT_FAIL = 'QUIZ_SUBMIT_FAIL';
export const GET_FITNESS_VIDEO_BY_ID = 'GET_FITNESS_VIDEO_BY_ID';
export const EDIT_FITNESS_VIDEO = 'EDIT_FITNESS_VIDEO';
export const GET_FAVORITE_VIDEOS = 'GET_FAVORITE_VIDEOS';
export const GET_FAVORITE_ARTICLES = 'GET_FAVORITE_ARTICLES';
export const GET_FAVORITE_RECIPES = 'GET_FAVORITE_RECIPES';
export const GET_USER_QUIZ_SCORE = 'GET_USER_QUIZ_SCORE';
export const GET_FEATURED_VIDEOS = 'GET_FEATURED_VIDEOS';
export const FAILED_GET_FEATURED_VIDEOS = 'FAILED_GET_FEATURED_VIDEOS';
export const SET_RECOMMENDED_ARTICLE = 'SET_RECOMMENDED_ARTICLE';
export const GET_WELLNESS_JOURNEYS_ID = 'GET_WELLNESS_JOURNEYS_ID';

/* DAILY WORKOUT ACTIONTYPES*/
export const GET_DAILY_WORKOUT_VIDEO = 'GET_DAILY_WORKOUT_VIDEO';
export const FAILED_GET_DAILY_WORKOUT_VIDEO = 'FAILED_GET_DAILY_WORKOUT_VIDEO';

export const SAVE_GARMIN_TOKEN = 'SAVE_GARMIN_TOKEN';
export const SAVE_FITBIT_TOKEN = 'SAVE_FITBIT_TOKEN';
export const SAVE_STRAVA_TOKEN = 'SAVE_STRAVA_TOKEN';
export const SAVE_WITHINGS_TOKEN = 'SAVE_WITHINGS_TOKEN';

export const SAVE_CONTACT_US_INFO = 'SAVE_CONTACT_US_INFO';
export const Delete_Workout_Type= 'Delete_Workout_Type'
/* Contact us ActionTypes */
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_ERROR = 'CONTACT_US_ERROR';

/* Pricing Enterprise*/
export const PRICING_ENTERPRISE_SUCCESS = 'PRICING_ENTERPRISE_SUCCESS';
export const PRICING_ENTERPRISE_ERROR = 'PRICING_ENTERPRISE_ERROR';

/* Newsletter*/

export const NEWSLETTER_SUCCESS = 'NEWSLETTER_SUCCESS';
export const NEWSLETTER_ERROR = 'NEWSLETTER_ERROR';

export const GET_WELLNESS_ASSESSMENT = 'GET_WELLNESS_ASSESSMENT';
export const SET_WELLNESS_ASSESSMENT = 'SET_WELLNESS_ASSESSMENT';
export const SET_BIOMETRIC_POPUP = 'SET_BIOMETRIC_POPUP';

export const GET_COMPANY_HEALTH_DETAILS = 'GET_COMPANY_HEALTH_DETAILS';
export const FAILED_GET_COMPANY_HEALTH_DETAILS = 'FAILED_GET_COMPANY_HEALTH_DETAILS';

export const SET_USER_AUTH = 'SET_USER_AUTH';
export const GET_USER_AUTH = 'GET_USER_AUTH';

/* company dashboard actionTypes */
export const GET_COMPANIES = 'GET_COMPANIES';
export const FAILED_GET_COMPANIES = 'FAILED_GET_COMPANIES';
export const SET_COMPANY = 'SET_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';

export const GET_WELLNESS_CHAMPION = 'GET_WELLNESS_CHAMPION';
export const FAILED_GET_WELLNESS_CHAMPION = 'FAILED_GET_WELLNESS_CHAMPION';
export const COMPANY_INFO_UPDATE = 'COMPANY_INFO_UPDATE';
export const GET_COMPANY_INFORMATION = 'GET_COMPANY_INFORMATION';

/* Wellness Admin */
export const GET_WELLNESS_ADMIN = 'GET_WELLNESS_ADMIN';
export const FAILED_GET_WELLNESS_ADMIN = 'FAILED_GET_WELLNESS_ADMIN';
export const GET_TOP_CHALLENGES = 'GET_TOP_CHALLENGES';

export const GET_TOP_EVENTS = 'GET_TOP_EVENTS';
export const GET_NEW_USERS = 'GET_NEW_USERS';
export const GET_MOST_ACTIVE_USERS = 'GET_MOST_ACTIVE_USERS';
export const GET_LEAST_ACTIVE_USERS = 'GET_LEAST_ACTIVE_USERS';

export const GET_TOP_FIVE_FITNESS_GOALS = 'GET_TOP_FIVE_FITNESS_GOALS';
export const GET_TOP_FIVE_DEVICES = 'GET_TOP_FIVE_DEVICES';
export const GET_TOP_FITNESS_ACTIVITIES = 'GET_TOP_FITNESS_ACTIVITIES';
export const GET_EXERCISE_PER_WEEK = 'GET_EXERCISE_PER_WEEK';
export const GET_GROUP_ACTIVITY_SUMMARY = 'GET_GROUP_ACTIVITY_SUMMARY';
export const GET_ENROLLED_USERS = 'GET_ENROLLED_USERS';
export const CSV_BUTTON_DISABLED = 'CSV_BUTTON_DISABLED';
export const GET_ELIGIBLE_EMPLOYEE = 'GET_ELIGIBLE_EMPLOYEE';
export const GET_CHALLENGES_AND_WELLNESS_COUNT = 'GET_CHALLENGES_AND_WELLNESS_COUNT';
export const GET_COMPANY_EDUCATION_DETAILS = 'GET_COMPANY_EDUCATION_DETAILS';
export const GET_USER_BIRTHDAY = 'GET_USER_BIRTHDAY';
export const GET_MINUTES_ACTIVE_DETAILS = 'GET_MINUTES_ACTIVE_DETAILS';
export const GET_FITNESS_REPORT = 'GET_FITNESS_REPORT';
/* FITNESS TAB LOADING*/
export const FITNESS_TAB_LOADING_TRUE = 'FITNESS_TAB_LOADING_TRUE';
export const FITNESS_TAB_LOADING_FALSE = 'FITNESS_TAB_LOADING_FALSE';
export const GET_TOP_WELLNESS_INITIATIVES = 'GET_TOP_WELLNESS_INITIATIVES';

export const GET_COMPANY_INITIATIVES = 'GET_COMPANY_INITIATIVES';
export const ADD_COMPANYINITIATIVES= 'ADD_COMPANYINITIATIVES';
export const SET_USER_COMPANY_DETAILS = 'SET_USER_COMPANY_DETAILS';
export const GET_USER_COMPANY_DETAILS = 'GET_USER_COMPANY_DETAILS';

export const SET_ENGAGEMENT_EMPLOYEE_COUNT = 'SET_ENGAGEMENT_EMPLOYEE_COUNT';

export const SET_COMPANY_BLOCK_DETAILS = 'SET_COMPANY_BLOCK_DETAILS';

export const GET_RECOGNITION_DETAILS = 'GET_RECOGNITION_DETAILS';
export const GET_EMPLOYEES_OF_THE_MONTH = 'GET_EMPLOYEES_OF_THE_MONTH';
export const GET_MOST_SOCIAL_USERS = 'GET_MOST_SOCIAL_USERS';
export const GET_RECOGNITION_ACTIVITY_DETAILS = 'GET_RECOGNITION_ACTIVITY_DETAILS';
export const GET_TOP_RECOGNIZED_RECOGNITION_DETAILS = 'GET_TOP_RECOGNIZED_RECOGNITION_DETAILS';
export const GET_TOP_RECEIVERS_RECOGNITION_DETAILS = 'GET_TOP_RECEIVERS_RECOGNITION_DETAILS';
export const GET_COMPANY_RECOGNITIONS = 'GET_COMPANY_RECOGNITIONS';
export const GET_RECOGNITION_FOR_DEPARTMENT = 'GET_RECOGNITION_FOR_DEPARTMENT';
export const GET_RECOGNITION_FOR_LOCATIONS = 'GET_RECOGNITION_FOR_LOCATIONS';
export const GET_RECOGNITION_FOR_ALL_DEPARTMENTS = 'GET_RECOGNITION_FOR_ALL_DEPARTMENTS';
export const GET_RECOGNITION_FOR_ALL_LOCATIONS = 'GET_RECOGNITION_FOR_ALL_LOCATIONS';
export const RECOGNITION_LOADING = 'RECOGNITION_LOADING';
export const IS_LOADING = 'IS_LOADING';
export const GET_EMPLOYEES_OF_THE_MONTHLYLY = 'GET_EMPLOYEES_OF_THE_MONTHLYLY';
export const GET_EMPLOYEES_OF_THE_QUARTER = 'GET_EMPLOYEES_OF_THE_QUARTER';
export const GET_RECOGNITION_MONTHLY = 'GET_RECOGNITION_MONTHLY';


/* Wellbeing Actions */
export const FETCH_HURDLE_IN_EATING = 'FETCH_HURDLE_IN_EATING';
export const FETCH_STRESS_CAUSING_FACTORS = 'FETCH_STRESS_CAUSING_FACTORS';
export const FETCH_FREQUENCY_FRUIT_EATING = 'FETCH_FREQUENCY_FRUIT_EATING';
export const FETCH_EATING_DRINKING_ADDEDSUGAR = 'FETCH_EATING_DRINKING_ADDEDSUGAR';
export const FETCH_STRESS_ABOUT_YOUR_FINANCES = 'FETCH_STRESS_ABOUT_YOUR_FINANCES';
export const FETCH_RELAXING_DATA = 'FETCH_RELAXING_DATA';
export const FETCH_HIGHLY_PROCESSED_FOOD_DETAILS = 'FETCH_HIGHLY_PROCESSED_FOOD_DETAILS';
export const FETCH_EATING_HEALTHY_DETAILS = 'FETCH_EATING_HEALTHY_DETAILS';
export const WELLBEING_LOADING = 'WELLBEING_LOADING';
export const WELLBEING_LOADING_FALSE = 'WELLBEING_LOADING_FALSE';
/** Health Tab */
export const FETCH_DO_YOU_SMOKE = 'FETCH_DO_YOU_SMOKE';
export const FETCH_PHYSICAL_EXAM = 'FETCH_PHYSICAL_EXAM';
export const FETCH_EXPERIENCE_PAIN = "FETCH_EXPERIENCE_PAIN";
export const FETCH_PRESCRIPTION_DRUGS = "FETCH_PRESCRIPTION_DRUGS";
export const FETCH_WELLBEING_ASSESSMENT_REPORTS = "FETCH_WELLBEING_ASSESSMENT_REPORTS";
export const FETCH_DAILY_WATER_INTAKE = "FETCH_DAILY_WATER_INTAKE";
export const FETCH_MEDICAL_DIAGNOSIS = "FETCH_MEDICAL_DIAGNOSIS";
export const FETCH_DAILY_AVERAGE_SLEEP = 'FETCH_DAILY_AVERAGE_SLEEP';
export const FETCH_PAIN_LOCATION = 'FETCH_PAIN_LOCATION';
export const FETCH_SICK_LAST_YEAR = 'FETCH_SICK_LAST_YEAR';
export const FETCH_SUGAR_INTAKE = 'FETCH_SUGAR_INTAKE';
export const FETCH_BURNOUT_FREQUENCY = "FETCH_BURNOUT_FREQUENCY";
export const FETCH_ALCOHOL_INTAKE = "FETCH_ALCOHOL_INTAKE";
export const SET_SELECTED_YEAR = "SET_SELECTED_YEAR";
export const HEALTH_TAB_LOADING = "HEALTH_TAB_LOADING";
export const HEALTH_TAB_LOADING_FALSE = "HEALTH_TAB_LOADING_FALSE";
export const SET_SELECTED_YEARV2 = "SET_SELECTED_YEARV2";
/** LifeStyle Tab*/
export const SET_COMMUNICATION_TEAM = "SET_COMMUNICATION_TEAM";
export const SET_DECISION_MAKING = "SET_DECISION_MAKING";
export const SET_VALUABLE_PART_OF_TEAM = "SET_VALUABLE_PART_OF_TEAM";
export const SET_WORK_INTERFERES = "SET_WORK_INTERFERES";
export const SET_STAND_BY_WORK = "SET_STAND_BY_WORK";
export const SET_MINUTES_EXERCISE = "SET_MINUTES_EXERCISE";
export const SET_WORK_IN_FREE_TIME = "SET_WORK_IN_FREE_TIME";
export const SET_HEALTH_LIMITING_PERFORMANCE = "SET_HEALTH_LIMITING_PERFORMANCE";
export const LIFE_STYLE_TAB_LOADING = "LIFE_STYLE_TAB_LOADING";
export const LIFE_STYLE_TAB_LOADING_FALSE = "LIFE_STYLE_TAB_LOADING_FALSE";
/* People HomePage actionTypes */
export const SAVE_BIOMETRIC_DETAILS = 'SAVE_BIOMETRIC_DETAILS';
export const SAVE_USER_BIOMETRIC = 'SAVE_USER_BIOMETRIC';
export const GET_SEARCH_PEOPLE = 'GET_SEARCH_PEOPLE';
export const GET_ADMIN_PEOPLE = 'GET_ADMIN_PEOPLE';

export const EDIT_WELLNESS_JOURNEY = 'EDIT_WELLNESS_JOURNEY';
export const ADD_WELLNESS_JOURNEY = 'ADD_WELLNESS_JOURNEY';
export const ADD_CATEGORY = 'ADD_CATEGORY';

export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES';

export const SET_COMPANY_FOR_USER = 'SET_COMPANY_FOR_USER';
export const GET_COMPANY_FOR_USER = 'GET_COMPANY_FOR_USER';

export const SET_REMOVE_ROLE = 'SET_REMOVE_ROLE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const SET_EMPLOYEE_COUNT = 'SET_EMPLOYEE_COUNT';
export const SET_PAGINATION_EMPLOYEE_COUNT = 'SET_PAGINATION_EMPLOYEE_COUNT';

export const SET_ADD_EMPLOYEE_SUCCESS = 'SET_ADD_EMPLOYEE_SUCCESS';
export const REMOVE_CSV = 'REMOVE_CSV';
export const USER_ROLES_LIST = 'USER_ROLES_LIST';

/* Amigo Search */
export const SET_AMIGO_COUNT = 'SET_AMIGO_COUNT';

/* Rewards */
export const GET_MONTHLY_REWARD_POINTS = 'GET_MONTHLY_REWARD_POINTS';

/*COMPANY LOGO */
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';
export const GET_COMPANY_START_DATE = 'GET_COMPANY_START_DATE';
/* Biometric */

export const BODY_MASS_PAYLOAD = 'BODY_MASS_PAYLOAD';
export const BAD_CHOLESTEROL = 'BAD_CHOLESTEROL';
export const GOOD_CHOLESTEROL = 'GOOD_CHOLESTEROL';
export const TRIGLYCERIDES_PAYLOAD = 'TRIGLYCERIDES_PAYLOAD';
export const AVERAGE_BIOMETRIC_DATA = 'AVERAGE_BIOMETRIC_DATA';
export const BODY_MASS_FETCH = 'BODY_MASS_FETCH';
export const BAD_CHOLESTEROL_FETCH = 'BAD_CHOLESTEROL_FETCH';
export const GOOD_CHOLESTEROL_FETCH = 'GOOD_CHOLESTEROL_FETCH';
export const TRIGLYCERIDES_PAYLOAD_FETCH = 'TRIGLYCERIDES_PAYLOAD_FETCH';
export const AVERAGE_BIOMETRIC_DATA_FETCH = 'AVERAGE_BIOMETRIC_DATA_FETCH';
export const GET_SYSTOLIC_BLO0DPRESSURE = 'GET_SYSTOLIC_BLO0DPRESSURE';
export const GET_DIASTOLIC_BLO0DPRESSURE = 'GET_DIASTOLIC_BLO0DPRESSURE';
export const GET_FASTING_USER =' GET_FASTING_USER';
export const GET_NON_FASTING_USER = 'GET_NON_FASTING_USER';
// export const Top_Health_PAYLOAD = 'Top_Health_PAYLOAD';
// export const Top_Health_FETCH = 'Top_Health_FETCH';

/* NOTIFICATION*/
export const GET_USER_NOTIFICATION_LIST = 'GET_USER_NOTIFICATION_LIST';
export const GET_READ_STATUS_COUNT_FOR_NOTIFICATION = 'GET_READ_STATUS_COUNT_FOR_NOTIFICATION';

/*CHALLENGE_EVENT_SPOUSE_DEPENDENT*/
export const CHALLENGE_EVENT_FOR_SPOUSE_DEPENDENT = 'CHALLENGE_EVENT_FOR_SPOUSE_DEPENDENT';
export const GET_COMPANY_WITH_SPOUSE_DEPENDENT = 'GET_COMPANY_WITH_SPOUSE_DEPENDENT';

//COMPLETED CHALLENGES LIST
export const GET_COMPLETED_CHALLENGES = 'GET_COMPLETED_CHALLENGES';
export const SET_COMPLETED_CHALLENGES = 'SET_COMPLETED_CHALLENGES';

/*CHALLENGE CHAT SCREEN*/
export const GET_USER_LIST_OF_CHALLENGE = 'GET_USER_LIST_OF_CHALLENGE';
export const GET_USER_LAST_SYNC = 'GET_USER_LAST_SYNC';
export const GET_USER_OVERVIEW_DETAILS = 'GET_USER_OVERVIEW_DETAILS';
export const GET_CHALLENGE_CSV = 'GET_CHALLENGE_CSV';
export const GET_CHALLENGE_DOWNLOAD_CSV_STATUS = 'GET_CHALLENGE_DOWNLOAD_CSV_STATUS';

/* LOG WORKOUTS*/
export const GET_USER_LOG_WORKOUT_CATEGORY = 'GET_USER_LOG_WORKOUT_CATEGORY';
export const USER_LOG_WORKOUT_LOADING_TRUE = 'USER_LOG_WORKOUT_LOADING_TRUE';
export const USER_LOG_WORKOUT_LOADING_FALSE = 'USER_LOG_WORKOUT_LOADING_FALSE';
export const GET_USER_LOG_WORKOUT = 'GET_USER_LOG_WORKOUT';

/*upcoming event*/
export const UPCOMING_EVENT = 'UPCOMING_EVENT';
export const GET_RECOMMENDED_THINGS_TO_COMPLETE = 'GET_RECOMMENDED_THINGS_TO_COMPLETE';

/*NUtrition Recipes*/
export const GET_RECIPES_DETAILS_BY_ID = 'GET_RECIPES_DETAILS_BY_ID';
export const GET_FEATURED_RECIPES_DETAILS = 'GET_FEATURED_RECIPES_DETAILS';
export const GET_RECIPES_CATEGORIES = 'GET_RECIPES_CATEGORIES';
export const GET_SELECTED_RECIPES_CATEGORIES = 'GET_SELECTED_RECIPES_CATEGORIES';
export const GET_RECIPES_CATEGORY_DETAILS = 'GET_RECIPES_CATEGORY_DETAILS';
export const GET_RECOMMENDED_RECIPES_DETAILS = 'GET_RECOMMENDED_RECIPES_DETAILS';
export const RECIPE_LOADING_TRUE = 'RECIPE_LOADING_TRUE';
export const RECIPE_LOADING_FALSE = 'RECIPE_LOADING_FALSE';

/*Survey*/
export const GET_SURVEY_LIBRARIES = 'GET_SURVEY_LIBRARIES';
export const SURVEY_LOADING = 'SURVEY_LOADING';
export const SURVEY_LOADING_FALSE = 'SURVEY_LOADING_FALSE';
export const FAILED_GET_SURVEY_LIBRARIES = 'FAILED_GET_SURVEY_LIBRARIES';
export const GET_SURVEY_DETAILS = 'GET_SURVEY_DETAILS';
export const FETCH_SURVEY_QUESTIONS = 'FETCH_SURVEY_QUESTIONS';
export const EMPTY_SURVEY_QUESTIONS = 'EMPTY_SURVEY_QUESTIONS';
export const GET_SURVEY_REPORT_DETAILS = 'GET_SURVEY_REPORT_DETAILS';
export const GET_SURVEY_OVERVIEW_DETAILS = 'GET_SURVEY_OVERVIEW_DETAILS';
export const GET_SURVEY_TOP_LOCATIONS = 'GET_SURVEY_TOP_LOCATIONS';
export const GET_SURVEY_TOP_DEPARTMENTS = 'GET_SURVEY_TOP_DEPARTMENTS';
export const GET_SURVEY_REPORTS_DATA = 'GET_SURVEY_REPORTS_DATA';

/*Admin Monthly task*/
export const GET_VIEW_MONTHLY_TASK_ICONS = 'GET_VIEW_MONTHLY_TASK_ICONS';
export const GET_ADMIN_MONTHLY_TASKS = 'GET_ADMIN_MONTHLY_TASKS';
export const GET_USER_TASK_STATUS = 'GET_USER_TASK_STATUS';

/*rewards*/
export const GET_USER_HISTORY_POINTS = 'GET_USER_HISTORY_POINTS';

/*my monthly task*/
export const GET_MONTHLY_TASK_LIST = 'GET_MONTHLY_TASK_LIST';

/*User Permission*/
export const GET_USER_PERMISSIONS_LIST = 'GET_USER_PERMISSIONS_LIST';

/*stripe */
export const PAYMENT_LOADING_TRUE = 'PAYMENT_LOADING_TRUE';
export const PAYMENT_LOADING_FALSE = 'PAYMENT_LOADING_FALSE';
export const GET_COMPANY_AND_CUSTOMER_DETAILS = 'GET_COMPANY_AND_CUSTOMER_DETAILS';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_ALL_INVOICES = 'GET_ALL_INVOICES';
export const GET_ALL_CARDS_DETAILS = 'GET_ALL_CARDS_DETAILS';
export const GET_ALL_ADDONS_DETAILS = 'GET_ALL_ADDONS_DETAILS';
export const GET_COMPANY_SUBSCRPTION_AND_ADDONS_DETAILS = 'GET_COMPANY_SUBSCRPTION_AND_ADDONS_DETAILS';
export const GET_BILLING_TAB_STATUS = 'GET_BILLING_TAB_STATUS';
/* Buddies List */
export const GET_BUDDIES_LIST_OF_CHALLENGES = 'GET_BUDDIES_LIST_OF_CHALLENGES';

/*Fitness*/
export const ADD_FITNESS_VIDEO = 'ADD_FITNESS_VIDEO';
// get exemplifiesvalues
export const FAILED_ADD_COMPANY_EXEMPLIFIES_VALUES = 'FAILED_ADD_COMPANY_EXEMPLIFIES_VALUES';
export const ADD_COMPANY_EXEMPLIFIES_VALUES = 'ADD_COMPANY_EXEMPLIFIES_VALUES';
export const GET_COMPANY_EXEMPLIFIES = 'GET_COMPANY_EXEMPLIFIES';
export const UPDATE_COMPANY_EXEMPLIFY_VALUES = 'UPDATE_COMPANY_EXEMPLIFY_VALUES';
export const GET_RECOGNITIONS_ICONS = 'GET_RECOGNITIONS_ICONS';
export const DELETE_COMPANY_EXEMPLIFIES_VALUES = 'DELETE_COMPANY_EXEMPLIFIES_VALUES';
/* GetUserREcognitionCount */
export const GET_USER_RECOGNITION_COUNT = 'GET_USER_RECOGNITION_COUNT';
/* My Upcoming Events */
export const GET_MY_UPCOMING_EVENTS = "GET_MY_UPCOMING_EVENTS";

/* CompanyFeature for show monthly task tab */
export const GET_COMPANY_FEATURES = "GET_COMPANY_FEATURES";
/* New Notification API  Day wise*/
export const GET_DAY_WISE_NOTIFICATIONS = 'GET_DAY_WISE_NOTIFICATIONS';
//Email Notifications
export const GET_USER_EMAIL_NOTIFICATIONS = 'GET_USER_EMAIL_NOTIFICATIONS';
/*HRA Pop up */
export const SET_HRA_POPUP = "SET_HRA_POPUP";
/* Workout History Calendar API */
export const GET_WORKOUT_HISTORY = 'GET_WORKOUT_HISTORY';
/*Day wise workout history */
export const GET_DAY_WISE_WORKOUT_HISTORY = 'GET_DAY_WISE_WORKOUT_HISTORY';
/*Workout Activity count */
export const GET_WORKOUT_ACTIVITY_COUNT = 'GET_WORKOUT_ACTIVITY_COUNT';
/* Workout activity by types */
export const GET_WORKOUT_ACTIVITY_BY_TYPES = 'GET_WORKOUT_ACTIVITY_BY_TYPES';
/* Suggestions for you */
export const GET_SUGGESTION_LIST = "GET_SUGGESTION_LIST";
export const SENT_SUGGESTION_FRIEND_REQUEST = "SENT_SUGGESTION_FRIEND_REQUEST";
/* getBirthday List */
export const GET_BIRTHDAYS_LIST = "GET_BIRTHDAYS_LIST";
export const GET_WORK_ANNIVERSARY_LIST = "GET_WORK_ANNIVERSARY_LIST";
export const GET_CHALLENGES_LIST = "GET_CHALLENGES_LIST";
export const GET_EVENT_LIST = "GET_EVENT_LIST";
/* add buddy */
export const ADD_BUDDY_ON_POST = 'ADD_BUDDY_ON_POST';
/*sent buddy resquest */
export const SEND_BUDDY_REQUEST = 'SEND_BUDDY_REQUEST';
/* remove buddy*/
export const REMOVE_BUDDY = "REMOVE_BUDDY";

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const GET_COMPANY_REWARDS = "GET_COMPANY_REWARDS";
export const GET_COMPANY_DOCUMENTS = "GET_COMPANY_DOCUMENTS";
export const CLEAR_COMPANY_DOCUMENTS = 'CLEAR_COMPANY_DOCUMENTS';
export const GET_COMAPANY_REWARD_PROGRAMS = 'GET_COMAPANY_REWARD_PROGRAMS';
export const GET_REWARDSREPORT_REPORT = 'GET_REWARDSREPORT_REPORT';
export const GET_MONTHLY_ACTIVITY_STATUS = 'GET_MONTHLY_ACTIVITY_STATUS';
export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const REJECT_FRIEND_REQUEST = 'REJECT_FRIEND_REQUEST';
//Language Translation
export const GET_USER_LANGUAGE = 'GET_USER_LANGUAGE';
export const GET_LANGUAGES = 'GET_LANGUAGES';
//Webinar Video
export const FAILED_GET_NEW_WEBINAR_VIDEOS = 'FAILED_GET_NEW_WEBINAR_VIDEOS';
export const  GET_NEW_WEBINAR_VIDEOS = 'GET_NEW_WEBINAR_VIDEOS';

//Live Events
export const GET_LIVE_EVENTS = "GET_LIVE_EVENTS";
export const GET_EVENTS_DETAILS_POINTS = "GET_EVENTS_DETAILS_POINTS";
//Admin challenges
export const CHALLENGE_OVERVIEW = "CHALLENGE_OVERVIEW";
export const CHALLENGE_TOP_LEADERS = "CHALLENGE_TOP_LEADERS";
export const CHALLENGE_TOP_PARTIPANTS = "CHALLENGE_TOP_PARTIPANTS";
export const ACTIVITY_CHALLENGE_DATA = "ACTIVITY_CHALLENGE_DATA";
export const BEHAVIOR_CHALLENGE_DATA = "BEHAVIOR_CHALLENGE_DATA";
export const SAVE_CHALLENGE_INFORMATION = "SAVE_CHALLENGE_INFORMATION";
export const GET_CHALLENGE_INFORMATION = 'GET_CHALLENGE_INFORMATION';
export const ADMIN_CHALLENGE_OVERVIEW = "ADMIN_CHALLENGE_OVERVIEW";
export const ADMIN_CHALLENGE_ACTIVE = "ADMIN_CHALLENGE_ACTIVE";

//Leaderboard
export const FITNESS_LEADERBOARD_DATA = "FITNESS_LEADERBOARD_DATA";
export const SOCIAL_USERS_DATA = "SOCIAL_USERS_DATA";
export const FITNESS_USERS_DATA = "FITNESS_USERS_DATA";
export const WELLNESS_USERS_DATA = "WELLNESS_USERS_DATA";
export const TOP_USER_TOTAL_POINTS = "TOP_USER_TOTAL_POINTS";
export const USER_RANK = "USER_RANK";
export const COMPANY_TEAM_POINTS = "COMPANY_TEAM_POINTS";
export const TEAM_USERS_POINTS = "TEAM_USERS_POINTS";
export const TEAMS_ICONS = "TEAM_ICONS";
export const GET_COMPANY_TEAMS_COUNT = "GET_COMPANY_TEAM_COUNT";
export const GET_COMPANY_TEAM_AND_MY_TEAM_FLAGS = "GET_COMPANY_TEAM_AND_MY_TEAM_FLAGS";
export const USER_TOTAL_POINTS = "USER_TOTAL_POINTS";


//Send Friend Request
export const GET_SEND_FRIEND_REQUESTS = "GET_SEND_FRIEND_REQUESTS";
//Rewards Progress Bar
export const COMPANY_REWARDS_TIER = "COMPANY_REWARDS_TIER";

//Daily Tip API
export const GET_TIP_BY_CATEGORY = "GET_TIP_BY_CATEGORY";
export const TIP_CATEGORY_COUNT = "TIP_CATEGORY_COUNT";
export const GET_TIP_BY_ID = "GET_TIP_BY_ID";
export const GET_TODAY_TIP = "GET_TODAY_TIP";
export const TIP_QUOTE_COUNT = "TIP_QUOTE_COUNT";
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const FETCH_USER_CONNECTED_DEVICES = 'FETCH_USER_CONNECTED_DEVICES';
export const FETCH_USER_INTEREST = 'FETCH_USER_INTEREST';
export const FETCH_USER_PROFILE_REWARD = 'FETCH_USER_PROFILE_REWARD';
export const FETCH_USER_COMPANY_DETAILS = 'FETCH_USER_COMPANY_DETAILS';
export const FETCH_USER_DEVICES = 'FETCH_USER_DEVICES';
export const FETCH_USER_SHOUT_OUT = 'FETCH_USER_SHOUT_OUT';
export const FETCH_INITIATIVES_DATA = 'FETCH_INITIATIVES_DATA';

//Team Creation By Admin
export const GET_COMPANY_TEAM_MEMBERS = "GET_COMPANY_TEAM_MEMBERS";
export const GET_COMPANY_TEAM_COUNT = "GET_COMPANY_TEAM_COUNT";
export const GET_USERS_ADD_TO_TEAM = "GET_USERS_ADD_TO_TEAM";
export const TEAM_DETAILS_BY_ID = "TEAM_DETAILS_BY_ID";
export const TEAM_ICONS="TEAM_ICONS";
export const TEAM_CREATED="TEAM_CREATED";
export const TEAM_NOT_CREATED="TEAM_NOT_CREATED";

//Wellbeing Reports Admin
export const ASSESMENT_DETAILS = "ASSESMENT_DETAILS";
export const HEALTH_WELLBEING_CARD = "HEALTH_WELLBEING_CARD";
export const TOP_WELLNESS_WINS = "TOP_WELLNESS_WINS";
export const TOP_ADDRESSABLE_HEALTH_RISK = "TOP_ADDRESSABLE_HEALTH_RISK";
export const GET_WELLBEING_CATEGORIES = "GET_WELLBEING_CATEGORIES";
export const GET_WBA_QUESTION_BY_CATEGORYID = "GET_WBA_QUESTION_BY_CATEGORYID";
export const TOP_HEALTH_IMPROVEMENT = "TOP_HEALTH_IMPROVEMENT";
